.site-description-item-profile-wrapper {
  color: rgba(0, 0, 0, 0.95);
  font-size: 14px;
  line-height: 1.5715;
}

.ant-drawer-body p.site-description-item-profile-p {
  display: block;
  margin-bottom: 8px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 16px;
  font-weight: bold;
  line-height: 1.5715;
}

.site-description-item-profile-p-label {
  display: inline-block;
  margin-right: 8px;
  color: rgba(0, 0, 0, 0.65);
}

.logoLogin {
  width: 60%;
}
.headerTop {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.container {
  margin: 8px 0;
}
.contents {
  padding: 10px;
}
.expires {
  display: none;
}
.coupon {
  cursor: pointer;
  display: flex;
  align-items: baseline;
  justify-content: space-between;
}
.topDetails {
  margin-bottom: 10px;
}
.detail {
  margin-bottom: 8px;
}
.adminName {
  display: none;
}

@media only screen and (min-width: 600px) {
  /* For tablets: */
  .logoLogin {
    width: 30%;
  }
  .headerTop {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .container {
    margin: 10px 0;
  }
  .contents {
    padding: 20px 50px;
  }
  .coupon {
    cursor: pointer;
    display: flex;
    align-items: baseline;
    justify-content: flex-start;
  }
  .expires {
    display: block;
  }
  .topDetails {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
  }
  .detail {
    width: 33%;
  }
  .adminName {
    display: block;
    cursor: pointer;
    color: white;
  }
  .avata {
    display: none;
  }
}
@media only screen and (min-width: 768px) {
  /* For desktop: */
  .logoLogin {
    width: 15%;
  }
  .headerTop {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .container {
    margin: 20px 0;
  }
  .contents {
    padding: 20px 50px;
  }
  .coupon {
    cursor: pointer;
    display: flex;
    align-items: baseline;
    justify-content: flex-start;
  }
  .expires {
    display: block;
  }
  .topDetails {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
  }
  .detail {
    width: 33%;
  }
  .adminName {
    display: block;
    cursor: pointer;
    color: white;
  }
  .avata {
    display: none;
  }
}
